import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Button,
  FormGroup,
  InputGroup,
  Form,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { scrapedDataSucess } from "../../store/slices/AdminSlice";

const ScrappingLoadingIndex = (props) => {

  const disptach = useDispatch();
  const webScrape = useSelector((state) => state.admin.webScrape);
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (!skipRender && !webScrape.loading) {
      if(Object.keys(webScrape.data).length > 0)
        {
          disptach(scrapedDataSucess(webScrape.data))
          props.setStep(5);
        }
      else
        props.setStep(3);
    }
  }, [webScrape]);

  useEffect(()=> {
    setSkipRender(false);
  }, [])
 
  return (
    <>
      <Row className="justify-content-md-center">
        <Col md={10} xl={6} lg={7}>
          <div className="layout-box">
            <div className="scrapping-loading-card">
              <div className="scrapping-loading-img-sec">
                <Image
                  className="crapping-loading-img"
                  src={window.location.origin + "/img/scrapping-loading.gif"}
                />
              </div>
              <div className="scrapping-loading-info">
                <h4>Scrapping...</h4>
                <p>System is scarping the data from the url</p>
              </div>
              <Button
                className="web-script-btn"
                onClick={() => props.setStep(5)}
              >
                Submit
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ScrappingLoadingIndex;
