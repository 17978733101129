import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Button,
  FormGroup,
  InputGroup,
  Form,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { loginStart } from "../../store/slices/AdminSlice";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader } from "../Helper/Loader";

const EmailIndex = (props) => {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.admin.login);
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Invalid Email Id"),
  });

  const handleSubmit = (values) => {
    dispatch(loginStart(values));
  };

  useEffect(() => {
    if(!skipRender && !login.loading && Object.keys(login.data).length > 0) {
      localStorage.setItem('email', login.data.user.email)
      props.setStep(7)
    }
    setSkipRender(false)
  }, [login.data])

  return (
    <>
      <Row className="justify-content-md-center">
        <Col md={10} xl={6} lg={7}>
          <div className="layout-box">
            <h3>Email ID</h3>
            <p>
              Enter email address where you would like to receive the report.
            </p>
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, setFieldTouched, errors }) => (
                <FORM className="web-script-submit-form">
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 18 15"
                      >
                        <path
                          fill="#DDE1E6"
                          d="M18 10.784c0 2.324-2.016 4.208-4.527 4.216H4.545C2.043 15 0 13.125 0 10.8v-.008s.005-3.688.013-5.543c0-.349.432-.544.727-.327a969.228 969.228 0 016.01 4.472 3.848 3.848 0 002.277.742c.828 0 1.638-.267 2.277-.75.048-.03 3.786-2.808 5.957-4.405.295-.217.73-.022.73.325.009 1.84.009 5.478.009 5.478z"
                        ></path>
                        <path
                          fill="#DDE1E6"
                          d="M17.529 2.228C16.749.868 15.216 0 13.527 0H4.545C2.857 0 1.323.868.544 2.228a.685.685 0 00.199.899l6.682 4.949c.468.35 1.035.524 1.602.524H9.045c.567 0 1.134-.174 1.602-.524l6.683-4.95a.685.685 0 00.199-.898z"
                        ></path>
                      </svg>
                    </InputGroup.Text>
                    <Field
                      name="email"
                      type="email"
                      className="form-control"
                      value={values.email}
                      autoFocus={true}
                    />
                  </InputGroup>
                  <ErrorMessage
                    component={"div"}
                    name="email"
                    className="errorMsg"
                  />
                  <div className="web-script-url-list-footer-sec">
                    <Button
                      className="no-bg-btn"
                      onClick={() => props.setStep(5)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#2050FF"
                        viewBox="0 0 24 24"
                      >
                        <path d="M4.943 5.606L1.024 9.525a3.585 3.585 0 000 4.95l3.919 3.919a1.5 1.5 0 102.121-2.121l-2.779-2.781 18.25-.023a1.5 1.5 0 001.5-1.5 1.5 1.5 0 00-1.5-1.5L4.3 10.492l2.764-2.765a1.5 1.5 0 00-2.121-2.121z"></path>
                      </svg>
                      Back
                    </Button>
                    <Button
                      className="web-script-btn"
                      // onClick={() => props.setStep(7)}
                      type="submit"
                      disabled={login.buttonDisable}
                    >
                      {login.buttonDisable ? <ButtonLoader /> : "Request OTP"}
                    </Button>
                  </div>
                </FORM>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default EmailIndex;
