import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Button,
  FormGroup,
  InputGroup,
  Form,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { viewReportStart } from "../../store/slices/AdminSlice";
import { ButtonLoader } from "../Helper/Loader";

const SuccessfullyCompletedIndex = (props) => {

	const dispatch = useDispatch();
	const viewReport = useSelector((state) => state.admin.viewReport);
	const [skipRender, setSkipRender] = useState(true);

	const handleSubmit = () => {
    dispatch(viewReportStart({
			web_scraper_unique_id: localStorage.getItem('web_scraper_unique_id'),
		}));
  };

	useEffect(() => {
    if(!skipRender && !viewReport.loading && Object.keys(viewReport.data).length > 0){
      const link = document.createElement("a");
      link.href = viewReport.data.url;
      link.setAttribute("target", '_blank'); //or any other extension
      link.setAttribute("download", `order_.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
		setSkipRender(false)
  }, [viewReport])

  return (
    <>
      <Row className="justify-content-md-center">
        <Col md={10} xl={6} lg={7}>
          <div className="layout-box">
            <div className="success-card">
              <Image
                className="success-logo"
                src={window.location.origin + "/img/chatgpt-logo.svg"}
              />
              <div className="successfully-completed-img-sec">
                <Image
                  className="crapping-loading-img"
                  src={window.location.origin + "/img/success-submited.gif"}
                />
              </div>
              <h3>Successfully Submitted </h3>
              <p>Your website and content are being submitted to ChatGPT</p>
            </div>

            <div className="web-script-url-list-footer-sec">
              <Button 
								className="web-script-btn" 
								onClick={handleSubmit}
								disabled={viewReport.buttonDisable}
							>
								{viewReport.buttonDisable ? <ButtonLoader /> : "View Report"}
							</Button>
              <Button
                className="web-script-outline-btn"
                onClick={() => {
									localStorage.setItem('web_scraper_unique_id', '')
									props.setStep(1)
								}}
              >
                Scrape More
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SuccessfullyCompletedIndex;
