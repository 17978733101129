import React from "react";
import { Form, Button, Image, Modal, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";


const ViewContentModal = (props) => {

  return (
    <>
      <Modal
        className="modal-dialog-center payment-link-created-modal"
        size="xl"
        centered
        show={props.viewContent}
        onHide={props.closeViewContentModal}
      >
        <Modal.Body style={{
      maxHeight: 'calc(100vh - 210px)',
      overflowY: 'auto'
     }}>
          <Link
            to="#"
            className="modal-close"
          >
            {/* <Image
              className="close-icon"
              src={window.location.origin + "/img/close-modal-icon.svg"}
            /> */}
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="#2050FF"
                data-name="Layer 1"
                viewBox="0 0 24 24"
                >
                <path d="M23.341 9.48l-3.501-6A5.016 5.016 0 0015.522 1H8.451a5.02 5.02 0 00-4.319 2.48l-3.501 6a5.01 5.01 0 000 5.039l3.501 6a5.016 5.016 0 004.318 2.48h7.071a5.02 5.02 0 004.319-2.48l3.5-6a5.01 5.01 0 000-5.039zm-1.729 4.031l-3.499 6a3.011 3.011 0 01-2.592 1.488H8.45a3.01 3.01 0 01-2.591-1.488l-3.5-6a3.004 3.004 0 010-3.023l3.499-6A3.011 3.011 0 018.45 3h7.071a3.01 3.01 0 012.591 1.488l3.5 6a3.004 3.004 0 010 3.023zm-5.905-3.805l-2.293 2.293 2.293 2.293a.999.999 0 11-1.414 1.414L12 13.413l-2.293 2.293a.997.997 0 01-1.414 0 .999.999 0 010-1.414l2.293-2.293-2.293-2.293a.999.999 0 111.414-1.414L12 10.585l2.293-2.293a.999.999 0 111.414 1.414z"></path>
            </svg>
          </Link>
          <div className="view-content-modal-sec">
            <div className="view-content-header-sec">
                <div className="scrapped-data-icon-sec">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="none"
                    viewBox="0 0 14 15"
                  >
                    <path
                      stroke="url(#paint0_linear_24_365)"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M4.333 10.333h5.334"
                    ></path>
                    <path
                      stroke="url(#paint1_linear_24_365)"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M4.287 7.5h3.38"
                    ></path>
                    <path
                      stroke="url(#paint2_linear_24_365)"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M5.667 1.125H7c.53 0 1.039.224 1.414.622.375.399.586.94.586 1.503v.708c0 .186.034.37.101.542.067.172.166.329.29.46.25.266.589.415.942.415H11c.53 0 1.039.224 1.414.622.375.399.586.94.586 1.503v1.417"
                    ></path>
                    <path
                      stroke="url(#paint3_linear_24_365)"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M12.027 4.34L9.973 2.16a3.237 3.237 0 00-2.34-1.035h-3.3c-.884 0-1.732.373-2.357 1.037A3.657 3.657 0 001 4.667v5.666c0 .94.351 1.84.976 2.505a3.237 3.237 0 002.357 1.037h5.334c.884 0 1.732-.373 2.357-1.037A3.657 3.657 0 0013 10.333V6.841c0-.937-.35-1.836-.973-2.5z"
                    ></path>
                    <defs>
                      <linearGradient
                        id="paint0_linear_24_365"
                        x1="4.333"
                        x2="10.008"
                        y1="10.844"
                        y2="12.665"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#0075FF"></stop>
                        <stop offset="1" stopColor="#6100FF"></stop>
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_24_365"
                        x1="4.287"
                        x2="8.096"
                        y1="8.01"
                        y2="8.785"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#0075FF"></stop>
                        <stop offset="1" stopColor="#6100FF"></stop>
                      </linearGradient>
                      <linearGradient
                        id="paint2_linear_24_365"
                        x1="5.667"
                        x2="14.246"
                        y1="5.1"
                        y2="5.586"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#0075FF"></stop>
                        <stop offset="1" stopColor="#6100FF"></stop>
                      </linearGradient>
                      <linearGradient
                        id="paint3_linear_24_365"
                        x1="1"
                        x2="15.039"
                        y1="7.63"
                        y2="8.425"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#0075FF"></stop>
                        <stop offset="1" stopColor="#6100FF"></stop>
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="scrapped-data-info-sec">
                  <h4>Home Page</h4>
                  <p>http://tools.buzzstream.com/meta-tag-extractor</p>
                </div>
            </div>
            <div className="view-content-body-sec">
                <div className="view-content-box">
                    <h4>Content</h4>
                    <p>Lorem ipsum dolor sit amet consectetur. Adipiscing penatibus volutpat vitae interdum magna aliquet. Dolor iaculis porttitor enim ultrices diam integer augue tincidunt vestibulum. Tempor amet dapibus amet ac porttitor sociis nec aliquet. Facilisis egestas sociis integer consectetur pretium. Risus rhoncus id in sollicitudin at orci nulla vitae. Sed diam amet elit ultricies arcu mollis lobortis erat et.</p>
                    <p>Pellentesque morbi gravida faucibus aenean faucibus vulputate. Nisi dui aliquam consequat in. Laoreet a lectus faucibus duis. Integer dui eget euismod morbi nisi venenatis elementum cras. Scelerisque tellus sit ac eget. Viverra commodo magna mi mauris tincidunt massa. Pellentesque lorem lacus quis lacus a. Varius diam id netus rhoncus in. In cursus ultricies eget urna ipsum mus gravida.</p>
                    <p>Eu ultrices maecenas elementum diam vitae pretium amet cras natoque. Mollis venenatis sed mauris elit. Odio leo eu cras nulla pretium. Accumsan egestas tempor mi cras fames facilisis sem. Scelerisque lobortis placerat ac odio magna venenatis eget. Non ut tellus luctus turpis vel pharetra sed.</p>
                    <p>Euismod odio id amet aliquet arcu adipiscing. Fringilla nisl mauris malesuada sollicitudin turpis. At ante neque aliquet eu sagittis ut. Consequat rhoncus odio augue ullamcorper lectus curabitur massa adipiscing. Turpis interdum facilisis scelerisque in pulvinar nulla lacus. Lobortis sagittis cursus pharetra mollis ut sagittis. Habitant consectetur sodales in id nullam at eget viverra. Quis egestas est non amet purus. Congue quis arcu nec erat. Orci fringilla euismod mauris volutpat duis aenean ultrices. Fringilla neque sit placerat nunc sed. Condimentum eleifend mauris arcu quam morbi. Facilisis ornare varius scelerisque ut magna egestas viverra id at. Odio sit et porttitor proin dolor a porttitor praesent.</p>
                    <p>Purus quisque ultrices metus aliquam scelerisque rhoncus sit imperdiet sodales. Mi malesuada aliquam scelerisque leo elit aliquet eget diam elementum. Nunc non mattis fringilla ipsum leo ac nunc. Ornare sed imperdiet mauris nibh. Ut sit tincidunt quis est fermentum orci eu nisl id. At neque lacinia ipsum quis odio commodo lectus eget. Sed orci pellentesque integer nibh magna. Quis consequat varius blandit sed viverra imperdiet velit. Pretium nibh a donec sit nibh. Praesent tortor viverra donec amet hac. Neque gravida scelerisque eros iaculis et. Fermentum fames turpis tristique lorem est mus ut.</p>
                </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewContentModal;
