import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Button,
  FormGroup,
  InputGroup,
  Form,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader } from "../Helper/Loader";
import { webLinkStart, webLinkDataSuccess } from "../../store/slices/AdminSlice";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";

const WebLinkIndex = (props) => {

  const dispatch = useDispatch();
  const webLink = useSelector((state) => state.admin.webLink);
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    website_url: Yup.string()
      .required("Website URL is required")
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Invalid URL!"
      ),
  });

  const handleSubmit = (values) => {
    dispatch(webLinkStart(values));
  };

  useEffect(() => {
    if (!skipRender && !webLink.loading && Object.keys(webLink.data).length > 0) {
      if(webLink.data.web_scraper.web_scrape_sub_pages.length > 0)
      {
        props.setStep(2);
        dispatch(webLinkDataSuccess(webLink.data.web_scraper));
        localStorage.setItem("web_scraper_unique_id", webLink.data.web_scraper.web_scraper_unique_id)
      }
      else{
        getErrorNotificationMessage("Try different website URL");
      }
    }
    setSkipRender(false);
  }, [webLink]);

  return (
    <>
      <Row className="justify-content-md-center">
        <Col md={10} xl={6} lg={7}>
          <div className="layout-box">
            <h3>Enter Website Address</h3>
            <p>
              Please provide the website link from which you would like to scrap
              data
            </p>
            <Formik
              initialValues={{
                website_url: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, setFieldTouched, errors }) => (
                <FORM className="web-script-submit-form">
                  <Form.Label>Website URL</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 19 19"
                      >
                        <path
                          fill="#929292"
                          d="M9.5.336A9.164 9.164 0 1018.664 9.5 9.175 9.175 0 009.5.336zm7.329 5.75h-3.61a12.501 12.501 0 00-2.107-4.51 8.115 8.115 0 015.717 4.51zm.757 3.414c0 .791-.115 1.578-.344 2.336H13.44a14.29 14.29 0 000-4.672h3.8c.23.758.346 1.545.345 2.336zM9.5 17.586a.157.157 0 01-.119-.053c-1.157-1.246-2.007-2.846-2.486-4.619h5.21c-.48 1.773-1.33 3.373-2.486 4.619a.158.158 0 01-.119.053zm-2.847-5.75a13.25 13.25 0 010-4.672h5.694a13.25 13.25 0 010 4.672H6.653zM1.414 9.5c0-.791.115-1.578.344-2.336H5.56a14.285 14.285 0 000 4.672h-3.8A8.059 8.059 0 011.413 9.5zM9.5 1.414a.157.157 0 01.119.053c1.157 1.246 2.007 2.846 2.486 4.619h-5.21c.48-1.773 1.33-3.373 2.486-4.619a.157.157 0 01.119-.053zm-1.612.162a12.502 12.502 0 00-2.107 4.51h-3.61a8.115 8.115 0 015.717-4.51zM2.171 12.914h3.61a12.501 12.501 0 002.107 4.51 8.115 8.115 0 01-5.717-4.51zm8.94 4.51a12.5 12.5 0 002.108-4.51h3.61a8.116 8.116 0 01-5.717 4.51z"
                        ></path>
                      </svg>
                    </InputGroup.Text>
                    <Field
                      name="website_url"
                      type="text"
                      className="form-control"
                      value={values.website_url}
                      autoFocus={true}
                    />
                  </InputGroup>
                  <ErrorMessage
                      component={"div"}
                      name="website_url"
                      className="errorMsg"
                    />
                  <div className="web-script-submit-btn-sec">
                    <Button
                      type="submit"
                      className="web-script-btn"
                      disabled={webLink.buttonDisable}
                    >
                      {webLink.buttonDisable ? <ButtonLoader /> : "Next"}
                    </Button>
                  </div>
                </FORM>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default WebLinkIndex;
