import React, { useState, useEffect } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import WebLinkIndex from "./WebLinkIndex";
import MetaDataIndex from "./MetaDataIndex";
import URLListIndex from "./URLListIndex";
import ScrappingLoadingIndex from "./ScrappingLoadingIndex";
import ScrappedDataIndex from "./ScrappedDataIndex";
import EmailIndex from "./EmailIndex";
import EmailVerificationIndex from "./EmailVerificationIndex";
import SuccessfullyCompletedIndex from "./SuccessfullyCompletedIndex";

const LandingPageIndex = (props) => {

  const [step, setStep] = useState(1);

  useEffect(() => {
    setStep(1)
  }, []);

  return (
    <>
      <div className="layout-bg-sec">
        <div className="layout-vertical-center">
          <Container>
            {step == 1 && (
              <div className="step-1">
                <WebLinkIndex setStep={setStep} />
              </div>
            )}
            {step == 2 && (
              <div className="step-2">
                <MetaDataIndex setStep={setStep} />
              </div>
            )}
            {step == 3 && (
              <div className="step-3">
                <URLListIndex setStep={setStep} />
              </div>
            )}
            {step == 4 && (
              <div className="step-4">
                <ScrappingLoadingIndex setStep={setStep} />
              </div>
            )}
            {step == 5 && (
              <div className="step-5">
                <ScrappedDataIndex setStep={setStep} />
              </div>
            )}
            {step == 6 && (
              <div className="step-6">
                <EmailIndex setStep={setStep} />
              </div>
            )}
            {step == 7 && (
              <div className="step-7">
                <EmailVerificationIndex setStep={setStep} />
              </div>
            )}
             {step == 8 && (
              <div className="step-7">
                <SuccessfullyCompletedIndex setStep={setStep} />
              </div>
            )}
          </Container>
        </div>
      </div>
    </>
  );
};

export default LandingPageIndex;
