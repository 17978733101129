import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
  emailVerifySuccess,
  emailVerifyFailure,
  resendOtpSuccess,
  resendOtpFailure,
  webLinkSuccess,
  webLinkFailure,
  scrapedDataListSuccess,
  scrapedDataListFailure,
  scrapedDataShowSuccess,
  scrapedDataShowFailure,
  loginSuccess,
  loginFailure,
  webScrapeSuccess,
  webScrapeFailure,
  viewReportSuccess,
  viewReportFailure,
  fineTuneSuccess,
  fineTuneFailure,
} from "../slices/AdminSlice";

function* loginAPI(action) {
  yield getCommonSaga({
    apiUrl: "login",
    payload: action.payload,
    success: loginSuccess,
    failure: loginFailure,
  });
}

function* emailVerifyAPI(action) {
  yield getCommonSaga({
    apiUrl: "verify_email",
    payload: action.payload,
    successCallback: true,
    success: data => {
      if (action.payload.remember) localStorage.setItem("token", data.access_token);
      else sessionStorage.setItem("token", data.access_token);
      store.dispatch(emailVerifySuccess(data.user));
      // store.dispatch(profileSuccess(data.user));
    },
    failure: emailVerifyFailure,
  });
}

function* resendOtpAPI(action) {
  yield getCommonSaga({
    apiUrl: "send_verification_code",
    payload: action.payload,
    success: resendOtpSuccess,
    failure: resendOtpFailure,
  });
}

function* webLinkAPI(action) {
  yield getCommonSaga({
    apiUrl: "scrape_website_urls", // Replace with the actual API endpoint
    payload: action.payload,
    successNotify: false,
    success: webLinkSuccess,
    failure: webLinkFailure,
  });
}

function* webScrapeAPI(action) {
  yield getCommonSaga({
    apiUrl: "scrape_start", 
    payload: action.payload,
    success: webScrapeSuccess,
    failure: webScrapeFailure,
  });
}


function* scrapedDataListAPI(action) {
  yield getCommonSaga({
    apiUrl: "scrape_status", // Replace with the actual API endpoint
    payload: action.payload,
    successNotify: false,
    success: scrapedDataListSuccess,
    failure: scrapedDataListFailure,
  });
}

function* scrapedDataShowAPI(action) {
  yield getCommonSaga({
    apiUrl: "scraped_data_show", // Replace with the actual API endpoint
    payload: action.payload,
    success: scrapedDataShowSuccess,
    failure: scrapedDataShowFailure,
  });
}

function* viewReportAPI(action) {
  yield getCommonSaga({
    apiUrl: "view_report", 
    payload: action.payload,
    successNotify: false,
    success: viewReportSuccess,
    failure: viewReportFailure,
  });
}

function* fineTuneAPI(action) {
  yield getCommonSaga({
    apiUrl: "fine_tune_start", 
    payload: action.payload,
    success: fineTuneSuccess,
    failure: fineTuneFailure,
  });
}

export default function* AdminSaga() {
  yield all([
    yield takeLatest('admin/loginStart', loginAPI),
    yield takeLatest('admin/emailVerifyStart', emailVerifyAPI),
    yield takeLatest('admin/resendOtpStart', resendOtpAPI),
    yield takeLatest('admin/webLinkStart', webLinkAPI),
    yield takeLatest('admin/webScrapeStart', webScrapeAPI),
    yield takeLatest('admin/scrapedDataListStart', scrapedDataListAPI),
    yield takeLatest('admin/scrapedDataShowStart', scrapedDataShowAPI),
    yield takeLatest('admin/viewReportStart', viewReportAPI),
    yield takeLatest('admin/fineTuneStart', fineTuneAPI),
  ]);
}
