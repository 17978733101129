import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Button,
  FormGroup,
  InputGroup,
  Form
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ViewContentModal from "./ViewContentModal";
import { useDispatch, useSelector } from "react-redux";
import { fineTuneStart, scrapedDataListStart, scrapedDataSucess } from "../../store/slices/AdminSlice";
import { ButtonLoader } from "../Helper/Loader";

const ScrappedDataIndex = (props) => {

  const dispatch = useDispatch();
  const scrapedData = useSelector((state) => state.admin.scrapedData); 
  const scrapedDataList = useSelector((state) => state.admin.scrapedDataList); 
  const fineTune = useSelector((state) => state.admin.fineTune); 
  const [viewContent, setViewContent] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [scrapingComplete, setScrapingComplete] = useState(false);

  const closeViewContentModal = () => {
    setViewContent(false);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!scrapingComplete) {
        dispatch(
          scrapedDataListStart({
            web_scraper_unique_id: localStorage.getItem("web_scraper_unique_id"),
          })
        );
      }
    }, 20000); // 20 seconds interval

    // Cleanup: Clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch, scrapingComplete]);

  useEffect(()=> {
    if(!skipRender && Object.keys(scrapedDataList.data).length > 0)
    { 
      if(scrapedDataList.data.web_scrape == true)
      {
        setScrapingComplete(true);
        dispatch(scrapedDataSucess(scrapedDataList.data))
      }
    }
    setSkipRender(false);
  }, [scrapedDataList])

  const handleSubmit = () => {
    dispatch(fineTuneStart({
      web_scraper_unique_id: localStorage.getItem("web_scraper_unique_id")
    }))
  }

  useEffect(()=> {
    if(!skipRender && Object.keys(fineTune.data).length > 0)
    { 
      props.setStep(6);
    }
    setSkipRender(false);
  }, [fineTune])

  return (
    <>
      <Row className="justify-content-md-center">
        <Col md={10} xl={6} lg={7}>
          <div className="layout-box">
            <h3>Scraped Data</h3>
            <p>The data below shows the scrapped data from the URLs you provided</p>
            <div className="scrapped-data-box">
              {scrapedData?.web_scraper?.web_scrape_sub_pages?.map((page, key) => (
                <div className="scrapped-data-card" key={key}>
                  <div className="scrapped-data-icon-sec">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="none"
                      viewBox="0 0 14 15"
                    >
                      <path
                        stroke="url(#paint0_linear_24_365)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M4.333 10.333h5.334"
                      ></path>
                      <path
                        stroke="url(#paint1_linear_24_365)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M4.287 7.5h3.38"
                      ></path>
                      <path
                        stroke="url(#paint2_linear_24_365)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M5.667 1.125H7c.53 0 1.039.224 1.414.622.375.399.586.94.586 1.503v.708c0 .186.034.37.101.542.067.172.166.329.29.46.25.266.589.415.942.415H11c.53 0 1.039.224 1.414.622.375.399.586.94.586 1.503v1.417"
                      ></path>
                      <path
                        stroke="url(#paint3_linear_24_365)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M12.027 4.34L9.973 2.16a3.237 3.237 0 00-2.34-1.035h-3.3c-.884 0-1.732.373-2.357 1.037A3.657 3.657 0 001 4.667v5.666c0 .94.351 1.84.976 2.505a3.237 3.237 0 002.357 1.037h5.334c.884 0 1.732-.373 2.357-1.037A3.657 3.657 0 0013 10.333V6.841c0-.937-.35-1.836-.973-2.5z"
                      ></path>
                      <defs>
                        <linearGradient
                          id="paint0_linear_24_365"
                          x1="4.333"
                          x2="10.008"
                          y1="10.844"
                          y2="12.665"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#0075FF"></stop>
                          <stop offset="1" stopColor="#6100FF"></stop>
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_24_365"
                          x1="4.287"
                          x2="8.096"
                          y1="8.01"
                          y2="8.785"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#0075FF"></stop>
                          <stop offset="1" stopColor="#6100FF"></stop>
                        </linearGradient>
                        <linearGradient
                          id="paint2_linear_24_365"
                          x1="5.667"
                          x2="14.246"
                          y1="5.1"
                          y2="5.586"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#0075FF"></stop>
                          <stop offset="1" stopColor="#6100FF"></stop>
                        </linearGradient>
                        <linearGradient
                          id="paint3_linear_24_365"
                          x1="1"
                          x2="15.039"
                          y1="7.63"
                          y2="8.425"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#0075FF"></stop>
                          <stop offset="1" stopColor="#6100FF"></stop>
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div className="scrapped-data-info-sec">
                    <h4>{page.meta_title}</h4>
                    <p>{page.website_url}</p>
                    <div className="scrapped-data-status-content-sec">
                      <div className={`scrapped-data-status-${page.web_scrap_status_formatted.toLowerCase()}-sec`}>
                        {scrapedData.web_scraper.web_scrap_status == 1 ? <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 17 17"
                      >
                        <path
                          stroke="#006FEE"
                          strokeMiterlimit="10"
                          d="M8.5 1C4.36 1 1 4.36 1 8.5 1 12.64 4.36 16 8.5 16c4.14 0 7.5-3.36 7.5-7.5C16 4.36 12.64 1 8.5 1z"
                        ></path>
                        <path
                          stroke="#006FEE"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M8.5 3.679v5.357h3.75"
                        ></path>
                        </svg> : scrapedData.web_scraper.web_scrap_status == 2 ?  <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 16 17"
                        >
                          <circle cx="7.735" cy="8.5" r="7.235" stroke="#2DA423"></circle>
                          <path
                            stroke="#2DA423"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.589"
                            d="M5.477 8.981l1.135 1.272 2.837-3.179"
                          ></path>
                        </svg>  : <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 16 17"
                        >
                          <circle cx="7.735" cy="8.5" r="7.235" stroke="#FF4B4B"></circle>
                          <path
                            stroke="#FF4B4B"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M5.47 6l5 5M10.47 6l-5 5"
                          ></path>
                        </svg> }
                        <span>{page.web_scrap_status_formatted}</span>
                      </div>
                      {/* <div className="scrapped-data-view-content-sec">
                        <Button className="scrapped-data-view-content-btn"  onClick={() => setViewContent(true)}>
                            View Content
                        </Button>
                      </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="web-script-url-list-footer-sec">
              <Button className="no-bg-btn" onClick={() => props.setStep(3)}>
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#2050FF"
                      viewBox="0 0 24 24"
                      >
                      <path d="M4.943 5.606L1.024 9.525a3.585 3.585 0 000 4.95l3.919 3.919a1.5 1.5 0 102.121-2.121l-2.779-2.781 18.25-.023a1.5 1.5 0 001.5-1.5 1.5 1.5 0 00-1.5-1.5L4.3 10.492l2.764-2.765a1.5 1.5 0 00-2.121-2.121z"></path>
                  </svg>
                  Back
              </Button>
              <Button className="web-script-btn" disabled={!scrapingComplete} onClick={handleSubmit}>
                {fineTune.buttonDisable ? <ButtonLoader/> :  "Submit to chatgpt"}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      {viewContent && <ViewContentModal
        viewContent={viewContent}
        closeViewContentModal={closeViewContentModal}
        setViewContent={setViewContent}
      />}
    </>
  )
}

export default ScrappedDataIndex;
