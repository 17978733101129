import Base from "./components/Base";
import { Provider } from "react-redux";
import store from "./store";
import { ToastContainer } from "react-toastify";

// css
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Provider store={store}>
      <Base />
    </Provider>
  );
}

export default App;
