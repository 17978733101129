import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Button,
  FormGroup,
  InputGroup,
  Form,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { webScrapeStart } from "../../store/slices/AdminSlice";
import { ButtonLoader } from "../Helper/Loader";

const URLListIndex = (props) => {
  const disptach = useDispatch();
  const webLinkData = useSelector((state) => state.admin.webLinkData);
  const webScrape = useSelector((state) => state.admin.webScrape);
  const [selectedSubPages, setSelectedSubPages] = useState([]);

  const handleSubmit = () => {
    props.setStep(4);
    disptach(webScrapeStart({ 
      web_scrape_sub_page_ids : selectedSubPages.map((page)=> page.id),
      web_scraper_unique_id: localStorage.getItem("web_scraper_unique_id") 
    }));
  };

  const handleCheckboxChange = (page) => {
    if (selectedSubPages.some((subPage) => subPage.id === page.id)) {
      setSelectedSubPages(selectedSubPages.filter((subPage) => subPage.id !== page.id));
    } else {
      setSelectedSubPages([...selectedSubPages, page]);
    }
  };

  const handleSelectAllChange = () => {
    if (selectedSubPages.length === webLinkData.web_scrape_sub_pages.length) {
      setSelectedSubPages([]);
    } else {
      setSelectedSubPages([...webLinkData.web_scrape_sub_pages]);
    }
  };
  
  const buttonText =
    selectedSubPages?.length === webLinkData.web_scrape_sub_pages.length
      ? "Deselect all"
      : "Select all";

  return (
    <>
      <Row className="justify-content-md-center">
        <Col md={10} xl={6} lg={7}>
          <div className="layout-box">
            <h3>Select URL</h3>
            <p>Please select the URL pages you'd like to scrape</p>
            <Form className="web-script-submit-form">
              <div className="web-script-url-list-header-sec">
                <div className="web-script-url-list-header-left-sec">
                  <h4>
                    Selected
                    <span>
                      <div className="count">{selectedSubPages.length}</div>
                    </span>
                  </h4>
                </div>
                <div className="web-script-url-list-header-right-sec">
                  <Form.Group className="mb-0" controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label={buttonText}
                      checked={selectedSubPages.length === webLinkData.web_scrape_sub_pages.length}
                      onChange={handleSelectAllChange}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="web-script-url-list-body-sec">
                <div className="web-script-url-list-box">
                  {webLinkData.web_scrape_sub_pages.map((page, key) => (
                    <label
                      className="web-script-url-list-item"
                      for={`payment-type-${page.id}`}
                      key={key}
                    >
                      <input
                        name="plan"
                        className="radio"
                        id={`payment-type-${page.id}`}
                        type="checkbox"
                        checked={selectedSubPages.some((subPage) => subPage.id === page.id)} // Check if the page is in selectedSubPages
                        onChange={() => handleCheckboxChange(page)}
                      />
                      <div className="web-script-url-list-card">
                        <div className="web-script-url-list-icon-sec">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="none"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fill="url(#paint0_linear_7_373)"
                              d="M9.665.335A9.665 9.665 0 1019.33 10 9.676 9.676 0 009.665.335zm7.73 6.064h-3.808a13.186 13.186 0 00-2.222-4.756 8.558 8.558 0 016.03 4.756zM18.192 10c0 .835-.122 1.665-.363 2.464h-4.009a15.07 15.07 0 000-4.928h4.009c.241.8.364 1.63.363 2.464zm-8.528 8.528a.165.165 0 01-.125-.056c-1.22-1.314-2.117-3.002-2.623-4.871h5.496c-.506 1.87-1.402 3.557-2.623 4.871a.165.165 0 01-.125.056zm-3.003-6.064a13.974 13.974 0 010-4.928h6.006c.292 1.63.292 3.298 0 4.928H6.662zM1.137 10A8.5 8.5 0 011.5 7.536h4.01a15.066 15.066 0 000 4.928H1.5A8.5 8.5 0 011.137 10zm8.528-8.528a.166.166 0 01.125.056c1.22 1.314 2.117 3.002 2.623 4.871H6.917C7.423 4.53 8.32 2.842 9.54 1.528a.166.166 0 01.125-.056zm-1.7.17A13.185 13.185 0 005.743 6.4H1.936a8.558 8.558 0 016.03-4.756zm-6.03 11.959h3.808a13.185 13.185 0 002.222 4.756 8.559 8.559 0 01-6.03-4.756zm9.43 4.756a13.186 13.186 0 002.222-4.756h3.807a8.56 8.56 0 01-6.03 4.756z"
                            ></path>
                            <defs>
                              <linearGradient
                                id="paint0_linear_7_373"
                                x1="0"
                                x2="22.605"
                                y1="10.197"
                                y2="11.557"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#0075FF"></stop>
                                <stop offset="1" stopColor="#6100FF"></stop>
                              </linearGradient>
                            </defs>
                          </svg>
                        </div>
                        <div className="web-script-url-list-info">
                          <h4>{page.meta_title}</h4>
                          <p>{page.website_url}</p>
                        </div>
                      </div>
                    </label>
                  ))}
                </div>
              </div>
              <div className="web-script-url-list-footer-sec">
                <Button className="no-bg-btn" onClick={() => props.setStep(2)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#2050FF"
                    viewBox="0 0 24 24"
                  >
                    <path d="M4.943 5.606L1.024 9.525a3.585 3.585 0 000 4.95l3.919 3.919a1.5 1.5 0 102.121-2.121l-2.779-2.781 18.25-.023a1.5 1.5 0 001.5-1.5 1.5 1.5 0 00-1.5-1.5L4.3 10.492l2.764-2.765a1.5 1.5 0 00-2.121-2.121z"></path>
                  </svg>
                  Back
                </Button>
                <Button
                  disabled={selectedSubPages.length == 0 || webScrape.buttonDisable}
                  className="web-script-btn"
                  onClick={() => handleSubmit()}
                >
                  {webScrape.buttonDisable ? <ButtonLoader/> : "Scrape data" }
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default URLListIndex;
