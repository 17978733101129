import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    webLink: getCommonInitialState(),
    webScrape: getCommonInitialState(),
    scrapedDataList: getCommonInitialState(),
    scrapedDataShow: getCommonInitialState(),
    login: getCommonInitialState(),
    emailVerify: getCommonInitialState(),
    resendOtp: getCommonInitialState(),
    viewReport: getCommonInitialState(),
    fineTune: getCommonInitialState(),
    webLinkData: {},
    scrapedData: {},
};

const AdminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {

        // Web Link
        webLinkStart: (state) => { state.webLink = getCommonStart() },
        webLinkSuccess: (state, { payload }) => { state.webLink = getCommonSuccess(payload) },
        webLinkFailure: (state, { payload }) => { state.webLink = getCommonFailure(payload) },

        // WebScrape
        webScrapeStart: (state) => { state.webScrape = getCommonStart() },
        webScrapeSuccess: (state, { payload }) => { state.webScrape = getCommonSuccess(payload) },
        webScrapeFailure: (state, { payload }) => { state.webScrape = getCommonFailure(payload) },

        // Scraped Data List
        scrapedDataListStart: (state) => { state.scrapedDataList = getCommonStart() },
        scrapedDataListSuccess: (state, { payload }) => { state.scrapedDataList = getCommonSuccess(payload) },
        scrapedDataListFailure: (state, { payload }) => { state.scrapedDataList = getCommonFailure(payload) },

        // Scraped Data Show
        scrapedDataShowStart: (state) => { state.scrapedDataShow = getCommonStart() },
        scrapedDataShowSuccess: (state, { payload }) => { state.scrapedDataShow = getCommonSuccess(payload) },
        scrapedDataShowFailure: (state, { payload }) => { state.scrapedDataShow = getCommonFailure(payload) },
        
        // Login
        loginStart: (state) => { state.login = getCommonStart() },
        loginSuccess: (state, { payload }) => { state.login = getCommonSuccess(payload) },
        loginFailure: (state, { payload }) => { state.login = getCommonFailure(payload) },

        // Email Verification
        emailVerifyStart: (state) => { state.emailVerify = getCommonStart() },
        emailVerifySuccess: (state, { payload }) => { state.emailVerify = getCommonSuccess(payload) },
        emailVerifyFailure: (state, { payload }) => { state.emailVerify = getCommonFailure(payload) },

        // Resend OTP Start
        resendOtpStart: (state) => { state.resendOtp = getCommonStart() },
        resendOtpSuccess: (state, { payload }) => { state.resendOtp = getCommonSuccess(payload) },
        resendOtpFailure: (state, { payload }) => { state.resendOtp = getCommonFailure(payload) },

        // Resend OTP Start
        viewReportStart: (state) => { state.viewReport = getCommonStart() },
        viewReportSuccess: (state, { payload }) => { state.viewReport = getCommonSuccess(payload) },
        viewReportFailure: (state, { payload }) => { state.viewReport = getCommonFailure(payload) },

        // Fine Tune
        fineTuneStart: (state) => { state.fineTune = getCommonStart() },
        fineTuneSuccess: (state, { payload }) => { state.fineTune = getCommonSuccess(payload) },
        fineTuneFailure: (state, { payload }) => { state.fineTune = getCommonFailure(payload) },

        // WebLink Data
        webLinkDataSuccess: (state, action) => { state.webLinkData = action.payload },

        // Scraped Data
        scrapedDataSucess: (state, action) => { state.scrapedData = action.payload }

    }
});


export const {
    
    emailVerifyFailure,
    emailVerifyStart,
    emailVerifySuccess,
    resendOtpFailure,
    resendOtpStart,
    resendOtpSuccess,
    webLinkFailure,
    webLinkStart,
    webLinkSuccess,
    webScrapeFailure,
    webScrapeStart,
    webScrapeSuccess,
    urlListSuccess,
    scrapedDataListFailure,
    scrapedDataListStart,
    scrapedDataListSuccess,
    scrapedDataShowFailure,
    scrapedDataShowStart,
    scrapedDataShowSuccess,
    loginFailure,
    loginStart,
    loginSuccess,
    viewReportFailure,
    viewReportStart,
    viewReportSuccess,
    webLinkDataSuccess,
    scrapedDataSucess,
    fineTuneStart,
    fineTuneFailure,
    fineTuneSuccess

} = AdminSlice.actions;

export default AdminSlice.reducer;