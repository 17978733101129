import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Button,
  FormGroup,
  InputGroup,
  Form,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const MetaDataIndex = (props) => {

  const dispatch = useDispatch();
  const webLinkData = useSelector((state) => state.admin.webLinkData);

  return (
    <>
      <Row className="justify-content-md-center">
        <Col md={10} xl={6} lg={7}>
          <div className="layout-box">
            <h3>Meta Details</h3>
            <p>
              Please review the metadata details of the website you gonna
              submit.
            </p>
            <Form className="web-script-submit-form">
              <div className="meta-data-box">
                <div className="meta-data-card">
                  <div className="meta-data-fav-icon-sec">
                    <Image
                      className="meta-data-fav-icon"
                      src={/*webLinkData.web_scrape_sub_pages[0].meta_picture ||*/ window.location.origin + "/img/webiste-fav-icon.png"}
                    />
                  </div>
                  <div className="meta-data-webiste-info-sec">
                    <h4>{webLinkData.title || webLinkData.website_url.slice(8)}</h4>
                    <p>{webLinkData.website_url}</p>
                    <div className="meta-data-page-count-sec">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="30"
                        fill="none"
                        viewBox="0 0 14 20"
                      >
                        <path
                          fill="#000"
                          d="M12.787 4.903L10.43 2.547a2.276 2.276 0 00-1.62-.672H2.833A2.294 2.294 0 00.542 4.167v11.666a2.294 2.294 0 002.291 2.292h8.334a2.294 2.294 0 002.291-2.292v-9.31c0-.611-.238-1.187-.671-1.62zm-.884.885c.073.073.121.164.17.255H9.5a.208.208 0 01-.208-.209V3.262c.09.048.181.096.255.17l2.356 2.356zm-.737 11.088H2.833a1.043 1.043 0 01-1.041-1.042V4.167c0-.575.467-1.042 1.041-1.042h5.209v2.708A1.46 1.46 0 009.5 7.292h2.708v8.541c0 .574-.467 1.042-1.041 1.042h-.001z"
                        ></path>
                        <path
                          fill="url(#paint0_linear_7_298)"
                          d="M3.875 9.167c0-.345.28-.625.625-.625h5a.625.625 0 010 1.25h-5a.625.625 0 01-.625-.625zM9.5 11.042h-5a.625.625 0 000 1.25h5a.625.625 0 000-1.25zm-2.5 2.5H4.5a.625.625 0 000 1.25H7a.625.625 0 000-1.25z"
                        ></path>
                        <defs>
                          <linearGradient
                            id="paint0_linear_7_298"
                            x1="3.875"
                            x2="11.184"
                            y1="11.73"
                            y2="12.17"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#0075FF"></stop>
                            <stop offset="1" stopColor="#6100FF"></stop>
                          </linearGradient>
                        </defs>
                      </svg>
                      <span>{webLinkData.web_scrape_sub_pages.length} pages</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="web-script-submit-btn-sec-1">
                <Button className="no-bg-btn" onClick={() => props.setStep(1)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#2050FF"
                    x="0"
                    y="0"
                    enableBackground="new 0 0 513.806 513.806"
                    version="1.1"
                    viewBox="0 0 513.806 513.806"
                    xmlSpace="preserve"
                  >
                    <path d="M66.074 228.731C81.577 123.379 179.549 50.542 284.901 66.045a192.81 192.81 0 0197.27 44.244l-24.853 24.853c-8.33 8.332-8.328 21.84.005 30.17a21.333 21.333 0 0015.078 6.246h97.835c11.782 0 21.333-9.551 21.333-21.333V52.39c-.003-11.782-9.556-21.331-21.338-21.329a21.333 21.333 0 00-15.078 6.246L427.418 65.04C321.658-29.235 159.497-19.925 65.222 85.835A256.527 256.527 0 002.885 219.408c-2.864 17.607 9.087 34.202 26.693 37.066 1.586.258 3.188.397 4.795.417 16.108-.174 29.629-12.185 31.701-28.16zM479.429 256.891c-16.108.174-29.629 12.185-31.701 28.16-15.503 105.352-113.475 178.189-218.827 162.687a192.81 192.81 0 01-97.27-44.244l24.853-24.853c8.33-8.332 8.328-21.84-.005-30.17a21.333 21.333 0 00-15.078-6.246H43.568c-11.782 0-21.333 9.551-21.333 21.333v97.835c.003 11.782 9.556 21.331 21.338 21.329a21.333 21.333 0 0015.078-6.246l27.733-27.733c105.735 94.285 267.884 85.004 362.17-20.732a256.504 256.504 0 0062.363-133.615c2.876-17.605-9.064-34.208-26.668-37.084a32.17 32.17 0 00-4.82-.421z"></path>
                  </svg>
                  Change URL
                </Button>
                <Button
                  className="web-script-btn"
                  onClick={() => props.setStep(3)}
                >
                  Next
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default MetaDataIndex;
