import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Button,
  FormGroup,
  InputGroup,
  Form,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader } from "../Helper/Loader";
import { emailVerifyStart, resendOtpStart } from "../../store/slices/AdminSlice";

const EmailVerificationIndex = (props) => {

	const dispatch = useDispatch();
  const emailVerify = useSelector((state) => state.admin.emailVerify);
  const resendOtp = useSelector((state) => state.admin.resendOtp);

  const [otp, setOtp] = useState("");
	const [skipRender, setSkipRender] = useState(true);

	const handleChange = (otp) => setOtp(otp);

	const handleSubmit = () => {
    dispatch(emailVerifyStart({
			email: localStorage.getItem('email'),
			verification_code: otp,
      web_scraper_unique_id: localStorage.getItem("web_scraper_unique_id")
		}));
  };

	const handleResendOtp = () => {
    dispatch(resendOtpStart({
			email: localStorage.getItem('email'),
		}));
  };

  useEffect(() => {
    if(!skipRender && !emailVerify.loading && Object.keys(emailVerify.data).length > 0) {
      localStorage.setItem('email', "")
      props.setStep(8)
    }
    setSkipRender(false)
  }, [emailVerify.data])

  return (
    <>
      <Row className="justify-content-md-center">
        <Col md={5} xl={5} lg={5}>
          <div className="layout-box">
            <h3>OTP Verification</h3>
            <p>We’ve sent an 6 digit OTP to your email Wrong Email?</p>
            <Form className="web-script-submit-form">
              <div className="verification-item">
                <OtpInput
                  value={otp}
									onChange={handleChange}
                  numInputs={6}
                  Separator={<span>-</span>}
                />
              </div>
              <Link to="#" className="resend-otp-link" onClick={handleResendOtp}>
								{resendOtp.buttonDisable ? "Loading" : "Resend OTP"}
              </Link>
              <div className="web-script-url-list-footer-sec">
                <Button className="no-bg-btn" onClick={() => props.setStep(6)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#2050FF"
                    viewBox="0 0 24 24"
                  >
                    <path d="M4.943 5.606L1.024 9.525a3.585 3.585 0 000 4.95l3.919 3.919a1.5 1.5 0 102.121-2.121l-2.779-2.781 18.25-.023a1.5 1.5 0 001.5-1.5 1.5 1.5 0 00-1.5-1.5L4.3 10.492l2.764-2.765a1.5 1.5 0 00-2.121-2.121z"></path>
                  </svg>
                  Back
                </Button>
                <Button
                  className="web-script-btn"
                  onClick={handleSubmit}
									type="submit"
                  disabled={!otp || otp.length < 6 || emailVerify.buttonDisable}
                >
                  {emailVerify.buttonDisable ? <ButtonLoader /> : "Verify"}
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default EmailVerificationIndex;
